/* Global Styles for the Townhouse Project */

/* General Reset */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #c0b6a5;
  color: #333;
  overflow: hidden;
}

h2 {
  color: rgba(168, 103, 61, 1);
  text-align: center;
  font-size: 14px;
  margin: 30px 0 20px 0;
  font-family: montserrat;
  text-transform: uppercase;
  letter-spacing: 3px;
  box-sizing: border-box;
}

button, .graphic-button {
  font-size: 14px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  font-family: montserrat;
  background-color: rgba(91, 134, 140, 1);
  color: #fff;
  text-transform: uppercase;
}

button.active {
  background-color: rgba(168, 103, 61, 1);
  color: #fff;
}

button:hover {
  background-color: rgba(168, 103, 61, 0.8);
}

a{
  color: rgba(168, 103, 61, 1);
}

.controls {
  left: 0;
}

.graphic-controls-container {
  right: 0;
}

/* Graphic Controls Section */
.graphic-controls-container, .controls {
  padding: 0 2em 1em;
  position: fixed;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 35%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-bottom: 120px;
}

.controls::-webkit-scrollbar, .order-form::-webkit-scrollbar {
  display: none;
}

/* Form Group */
.form-group {
  margin: 20px 0;
}

.form-text{
  color: #bddee0;
  font-family: montserrat;
  text-align: center;
}

.form-label {
  text-align: center;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  font-family: montserrat;
  color: #bddee0;
  text-transform: uppercase;

}

.form-input, .form-textarea {
  width: 100%;
  padding: 10px;
  font-size: 0.9rem;
  border: none;
  border-radius: 20px;
  transition: border-color 0.3s ease;
  font-family: montserrat;
  box-sizing: border-box;
  text-transform: uppercase;
}

.form-input:focus, .form-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.form-textarea {
  resize: vertical;
  min-height: 30px;
}

/* Required Field Marker */
.required {
  color: #ff4d4f;
}

/* Submit Button */
.submit-button, .cancel {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  font-size: 1rem;
  color: #fff;
  background-color: rgba(91, 134, 140, 1);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: montserrat;
  text-transform: uppercase;
}
.cancel{
  background-color: rgba(168, 103, 61, 1);

}

.submit-button:hover {
  background-color: #455051;
}

.submit-button:active {
  background-color: #004085;
}

/* Product Overlay */
.product-overlay img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
}

/* Controls Layout */
.product-controls,
.color-controls,
.graphic-controls,
.graphic-color-controls,
.size-controls {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Consistent button sizes */
  gap: 10px;
  justify-items: stretch;
  direction: ltr;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: #333;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 90%;
  animation: fadeIn 0.3s ease-in-out;
  font-size: 16px;
}

/* Modal Buttons */
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-title{
  color: rgba(168, 103, 61, 1);
  margin: 10px;
  font-size: 16px;
}




/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}




/* Order Button on the Right */
.order-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}



.order-button:hover {
  background-color: #704b29;
}

.order-button:active {
  background-color: #50351c;
}

.order-button {
  margin-top: 20px;
  text-align: center; /* Center the button horizontally within the parent container */
}


.submit-button:hover {
  background-color: #0056b3;
}

.submit-button, .open-order-button {
  padding: 10px 20px;
  background-color: rgba(91, 134, 140, 1);
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: rgba(91, 134, 140, 0.8);
}

.submit-button:active {
  background-color: rgba(90, 134, 140, 1);
}
.open-order-button{
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: rgba(168, 103, 61, 1);
  z-index: 9;
  border-radius: 0px;
  opacity: 0.9;

}

@media only screen and (max-width: 768px) {
  button, .graphic-button {
    font-size: 10px;
    padding: 5px;
  }
  .controls, .graphic-controls-container{
    padding: 0 1em 50px;
  }
  .product-controls,
  .color-controls,
  .graphic-controls,
  .graphic-color-controls,
  .size-controls {
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr)); /* Consistent button sizes */
    gap: 5px;
  }
  .preview-container{
    transform: scale(0.8);
    transform-origin: top;
  }
  h2 {
    font-size: 10px;
    margin: 10px 0 10px 0;

  }
  .modal-content {
    transform: scale(0.9);
  }

}
